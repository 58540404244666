<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu"><a class="ashen-link" @click="$router.push('account')">アカウント設定</a><i
          class="fas fa-angle-right form-control-color"></i> 生年月日変更</p>
    </div>
    <div class="card text-first border-0 border-top">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4">生年月日変更</h3>
          <div class="col">
            <label for="exampleInputPassword1">現在のパスワード</label>
            <input v-model="authenticationPassword" class="form-control" type="password">
            <div v-show="errors['authenticationPassword']" id="err_password" class="form-error">
              {{ errors['authenticationPassword'] }}
            </div>
          </div>
        </div>
        <form class="form card card-body responsive border-0 p-0 pt-3">
          <label for="birthday">生年月日</label>
          <div class="row dob-section">
            <div class="d-inline-flex">
              <div class="input-group custom-select-year parent-block">
                <select id="inputGroupSelect02" v-model="year" class="form-control select_year form-select">
                </select>
              </div>
              <div class="input-group custom-select-year parent-block">
                <select id="inputGroupSelect02" v-model="month" class="form-control select_month form-select">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <div class="input-group custom-select-year parent-block m-r-0">
                <select id="inputGroupSelect02" v-model="date" class="form-control select_date form-select">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
              </div>
            </div>
            <div v-show="errors['birthday']" id="err_inputBirth" class="form-error">{{ errors['birthday'] }}</div>
          </div>
        </form>
        <div class="row mt-5">
          <div class="mt-sm-4 col col-sm-6 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none light border-0 custom-btn"
                    @click="$router.push('account')">
              キャンセル
            </button>
          </div>
          <div class="col col-sm-6 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-4 custom-btn"
                    v-on:click="doChange">
              変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import {mapGetters} from "vuex";
import {SetAdminUpdateUser} from "jsadminclient/index";
import Common from 'jsadminclient/common'

export default {
  name: "EditBirthday",
  data() {
    return {
      year: '',
      month: '',
      date: '',
      authenticationPassword: '',
      errors: []

    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetAdminUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = this.authenticationPassword
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = this.year + "/" + this.month + "/" + this.date
      params.birthdayUpdate = true
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        Common.showToast("生年月日が変更されました。")
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.year = Common.getYear(jsonObject.birthday)
        this.month = Common.getMonth(jsonObject.birthday)
        this.date = Common.getDate(jsonObject.birthday)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },

  mounted() {
    this.getUser()

    var select_year = document.getElementsByClassName("select_year")[0];
    var select_month = document.getElementsByClassName("select_month")[0];
    var select_date = document.getElementsByClassName("select_date")[0];

    let d = new Date();
    let year = d.getFullYear();

    for (var i = 1920; i <= year; i++) {
      let op = document.createElement('option');
      op.value = i;
      op.text = i;

      select_year.appendChild(op);
    }
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }
  .border-top {
    border: 0px !important;
  }
}

.dob-section {
  padding-left: 0px !important;
}

.content {
  &.editbirthday {
    .form-control {
      border-style: none;
    }
  }
}
</style>
