<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu"><a class="ashen-link" @click="$router.push('account')">アカウント設定</a><i
          class="fas fa-angle-right form-control-color"></i> 国籍変更</p>
    </div>
    <div class="card text-first border-0 border-top">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4">国籍変更</h3>
        </div>
        <div class="row  mx-auto mt-0 mt-sm-4">
          <select v-model="nationalityId" class="form-control form-select citizenship">
            <option v-for="obj in nationalityList"
                    v-bind:value="obj.nationalityId">{{ obj.nationalityName }}
            </option>
          </select>
        </div>
        <div class="row">
          <div v-show="errors['nationalityId']" id="err_country" class="form-error">{{ errors['nationalityId'] }}</div>
        </div>
        <div class="row mt-5">
          <div class="mt-sm-4 col col-sm-6 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none light border-0 custom-btn"
                    @click="$router.push('account')">
              キャンセル
            </button>
          </div>
          <div class="col col-sm-6 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-4 custom-btn"
                    v-on:click="doChange">
              変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AdminNationalityItems, AdminNationalityItem, SetAdminUpdateUser} from "jsadminclient";
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";

export default {
  name: "EditCountry",
  data() {
    return {
      nationalityList: [],
      nationalityId: 1,
      errors: [],
      loading: true
    }
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetAdminUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = this.nationalityId
      params.nationalityIdUpdate = true
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        Common.showToast("国籍が変更されました。")
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.nationalityId = jsonObject.nationalityId
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getNationality() {
      const token = this.config.token
      try {
        const api = Common.createSystemApi(token)
        const jsonObject = await api.systemNationalitiesGetAsync()
        const result = AdminNationalityItems.createFromJson(jsonObject)

        this.nationalityList = result.items

      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    }
  },
  mounted() {
    this.getNationality()
    this.getUser()
    this.loading = true
  }
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 576px) {
  .topic-path {
    display: none;
  }
  .border-top {
    border: 0px !important;
  }
}

.content {
  &.editcountry {
    .form-control {
      border-style: none;
    }
  }
}
</style>
