<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu"><a class="ashen-link" @click="$router.push('account')">アカウント設定</a><i
          class="fas fa-angle-right form-control-color"></i>新型コロナワクチン接種変更</p>
    </div>
    <div class="card text-first border-0 border-top">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4 mt-sm-2">新型コロナワクチン接種変更</h3>
          <div class="col">
            <div class="form-group mt-3">
              <label>新型コロナワクチン接種</label>
              <div class="col">
                <div class="form-group mt-3">
                  <div class="row  mx-auto mt-0 mt-sm-4">
                    <select v-model="coronaVaccinationCount" class="form-control form-select citizenship">
                    <option :value="0">なし</option>
                    <option :value="1">1回目</option>
                    <option :value="2">2回目</option>
                    <option :value="3">3回目</option>
                    <option :value="4">4回目</option>
                    <option :value="5">5回目</option>
                    <option :value="6">6回目</option>
                    <option :value="7">7回目</option>
                    <option :value="8">8回目</option>
                    <option :value="9">9回目</option>
                    <option :value="10">10回目</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">最後に接種した日</label>
            <div class="input-group">
              <Calender :currentDate="latestCoronaVaccinationDate"
                        :disable="coronaVaccinationCount === 0"
                        :type="'vaccination'"
                        @update="updateDate"
              ></Calender>

              <!--              <input v-model="latestCoronaVaccinationDate" :disabled="!hasCoronaVaccination"-->
              <!--                     class="form-control mt-2 full-width-input"-->
              <!--                     maxlength="10"-->
              <!--                     placeholder="yyyy/mm/ddで入力"-->
              <!--                     type="text"-->
              <!--                     @keydown="onKeyDown"/>-->
              <!--              <div class="input-group-append mt-2">-->
              <!--                <input :disabled="!hasCoronaVaccination" :value="latestCoronaVaccinationDate"-->
              <!--                       class="form-control datepicker"-->
              <!--                       type="date"-->
              <!--                       @change="getVaccinationDateFormat">-->
              <!--              </div>-->
            </div>
            <!--                                <input :disabled="coronaVaccinationStatus === 'なし'"-->
            <!--                                       class="form-control mt-2 half-width-input"-->
            <!--                                       type="text" v-model="user.latestCoronaVaccinationDate"/>-->
          </div>
          <div v-show="errors['latestCoronaVaccinationDate']" class="form-error">
            {{ errors['latestCoronaVaccinationDate'] }}
          </div>
        </div>
        <!--                <div class="row">-->
        <!--                    <label class="mt-3 mb-2">-->
        <!--                        最後に接種した日-->
        <!--                    </label>-->
        <!--                    <div class="input-group mb-3">-->
        <!--                        <input class="form-control" pattern="yyyy-mm-dd" type="date"-->
        <!--                               v-model="latestCoronaVaccinationDate">-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="form-error" id="err_lastInoculatinoDate" v-show="errors['latestCoronaVaccinationDate']">{{-->
        <!--                    errors['latestCoronaVaccinationDate'] }}-->
        <!--                </div>-->
        <div class="row mt-4">
          <div class="mt-sm-4 col col-sm-6 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none light border-0 custom-btn"
                    @click="$router.push('account')">
              キャンセル
            </button>
          </div>
          <div class="col col-sm-6 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-4 custom-btn"
                    v-on:click="doChange">
              変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SetAdminUpdateUser} from "jsadminclient";
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";
import Calender from "../components/layouts/Calendar";

export default {
  name: "VaccineChange",
  components: {Calender},

  props: {
    vaccinationData: {type: Object, default: null}
  },
  data() {
    return {
      hasCoronaVaccination: '',
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      vaccinationDate: '',
      coronaVaccinationDate: '',
      errors: []
    }
  },
  watch: {
    'hasCoronaVaccination': function (newValue, oldValue) {
      this.latestCoronaVaccinationDate = newValue ? this.vaccinationDate : ''
    }
  },
  created() {
    this.vaccinationDate = Common.dateFormatConvert(this.vaccinationData.latestCoronaVaccinationDate)
    this.hasCoronaVaccination = this.vaccinationData.hasCoronaVaccination
  },
  computed: {
    ...mapGetters(['config']),
    latestCoronaVaccinationDateForDatePicker() {
      if (this.latestCoronaVaccinationDate === null) {
        return null
      }
      return Common.dateFormatConvertForDatePicker(this.latestCoronaVaccinationDate)
    }
  },
  methods: {
    createSetUpdateUser(step) {
      var params = new SetAdminUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = Number(this.hasCoronaVaccination) === 1 ? true : false
      params.coronaVaccinationCount = this.coronaVaccinationCount
      params.latestCoronaVaccinationDate = Common.dateFormatConvert(this.latestCoronaVaccinationDate)
      params.coronaVaccinationUpdate = true
      params.latestPcrTestResult = 0
      params.latestPcrTestDate = ''
      params.latestPcrTestUpdate = false

      return params
    },
    getVaccinationDateFormat(e) {
      this.vaccinationDate = moment(e.target.value).format('YYYY/MM/DD');
      this.latestCoronaVaccinationDate = this.vaccinationDate
    },
    isAllowedKeyCodes(keyCode) {
      const allowedKeyCodes = [8, 46, 111, 191]
      for (let i = 48; i < 58; i++) {
        allowedKeyCodes.push(i)
      }
      for (let i = 96; i < 106; i++) {
        allowedKeyCodes.push(i)
      }
      return allowedKeyCodes.includes(keyCode)
    },
    onKeyDown(event) {
      const keyCode = event.keyCode
      const len = this.latestCoronaVaccinationDate.length
      if (!this.isAllowedKeyCodes(keyCode)) {
        event.preventDefault()
      }
      if (len !== 4 || len !== 7) {
        if (keyCode === 111) {
          event.preventDefault()
        }
      }
      if (len === 4 && keyCode !== 8 && keyCode !== 46) {
        this.latestCoronaVaccinationDate += '/'
      }
      if (len === 7 && keyCode !== 8 && keyCode !== 46) {
        this.latestCoronaVaccinationDate += '/'
      }
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {

        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)

        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        Common.showToast("新型コロナワクチン接種が変更されました。")
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.coronaVaccinationCount = jsonObject.coronaVaccinationCount
        this.hasCoronaVaccination = jsonObject.hasCoronaVaccination
        this.latestCoronaVaccinationDate = Common.dateFormatConvert(jsonObject.latestCoronaVaccinationDate)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    updateDate(date) {
      this.latestCoronaVaccinationDate = date
    },
  },
  mounted() {
    this.getUser()
  }
}

</script>

<style lang="scss" scoped>
.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

.datepicker {
  &::-webkit-calendar-picker-indicator {
    background: url("../assets/images/calendar-event.svg") no-repeat;
  }
}

:disabled {
  background: #FAF7F9 !important;
}

@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }
  .border-top {
    border: 0px !important;
  }
}

.content {
  &.vaccinechange {
    .form-control {
      border-style: none;
    }
  }
}
</style>
