<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="row m-t-10">
      <div class="col-lg-10 col-md-12 mx-auto">
        <h2>お問い合わせ</h2>
        <p class="mt-4">健康情報管理システムHeaLoの使い方につきましては、以下URLよりお問い合わせください。</p>
        <a class="mt-2 color-pink" href="https://jta-tennis.atlassian.net/servicedesk/customer/portal/2"
           target="_blank">https://jta-tennis.atlassian.net/servicedesk/customer/portal/2</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>

<style scoped>
h2 {
  color: #333333;
  font-size: 30px;
}

p {
  color: #333333;
  font-size: 16px;
}

a {
  color: #333333;
  font-size: 16px;
}

</style>